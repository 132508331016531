import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
interface TimeRange {
    readonly type: string;
}
interface ExecutionState {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly global_override: ExecutionStateGlobalOverride;
    readonly additional_parameters: {
        readonly [_key: string]: Object;
    };
    readonly queries: {
        readonly [_key: string]: ExecutionStateGlobalOverride;
    };
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface SearchTypeExecutionState {
    readonly offset: number;
    readonly limit: number;
    readonly after: unknown[];
}
interface MessagesRequest {
    readonly timerange: TimeRange;
    readonly chunk_size: number;
    readonly streams: string[];
    readonly limit: number;
    readonly fields_in_order: string[];
    readonly time_zone: string;
    readonly query_string: ElasticsearchQueryString;
}
interface ElasticsearchQueryString {
    readonly query_string: string;
}
interface ResultFormat {
    readonly timerange: AbsoluteRange;
    readonly filename: string;
    readonly limit: number;
    readonly execution_state: ExecutionState;
    readonly time_zone: string;
    readonly fields_in_order: string[];
}
interface Object {
}
interface BackendQuery {
}
interface ExecutionStateGlobalOverride {
    readonly timerange: TimeRange;
    readonly keep_search_types: string[];
    readonly offset: number;
    readonly query: BackendQuery;
    readonly limit: number;
    readonly search_types: {
        readonly [_key: string]: SearchTypeExecutionState;
    };
    readonly keep_queries: string[];
}
/**
 * Create job to export a defined set of messages
 */
export function create(arg0?: MessagesRequest): Promise<string> {
    return __request__('POST', '/views/export', arg0, {}, {
        'Accept': []
    });
}
/**
 * Create job to export search result
 * @param searchId ID of an existing Search
 * @param Optionaloverrides Optional overrides
 */
export function createForSearch(searchId?: string, Optionaloverrides?: ResultFormat): Promise<string> {
    return __request__('POST', `/views/export/${searchId}`, Optionaloverrides, {}, {
        'Accept': []
    });
}
/**
 * Create job to export search type
 * @param searchId ID of an existing Search
 * @param searchTypeId ID of a Message Table contained in the Search
 * @param Optionaloverrides Optional overrides
 */
export function createForSearchType(searchId?: string, searchTypeId?: string, Optionaloverrides?: ResultFormat): Promise<string> {
    return __request__('POST', `/views/export/${searchId}/${searchTypeId}`, Optionaloverrides, {}, {
        'Accept': []
    });
}
