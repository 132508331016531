import __request__ from 'routing/request';
interface Startpage {
    readonly id: string;
    readonly type: string;
}
interface UserSummary {
    readonly service_account: boolean;
    readonly preferences: {
        readonly [_key: string]: Object;
    };
    readonly timezone: string;
    readonly session_active: boolean;
    readonly roles: string[];
    readonly last_name: string;
    readonly account_status: 'enabled' | 'disabled' | 'deleted';
    readonly external: boolean;
    readonly full_name: string;
    readonly session_timeout_ms: number;
    readonly startpage: Startpage;
    readonly last_activity: string;
    readonly read_only: boolean;
    readonly permissions: string[];
    readonly id: string;
    readonly client_address: string;
    readonly first_name: string;
    readonly email: string;
    readonly username: string;
    readonly grn_permissions: string[];
}
interface RoleResponse {
    readonly read_only: boolean;
    readonly permissions: string[];
    readonly name: string;
    readonly description: string;
}
interface Object {
}
interface RoleMembershipResponse {
    readonly role: string;
    readonly users: UserSummary[];
}
interface RolesResponse {
    readonly total: number;
    readonly roles: RoleResponse[];
}
/**
 * Create a new role
 * @param JSONbody The new role to create
 */
export function create(JSONbody: RoleResponse): Promise<unknown> {
    return __request__('POST', '/roles', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all roles
 */
export function listAll(): Promise<RolesResponse> {
    return __request__('GET', '/roles', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing role
 * @param JSONBody The new representation of the role
 */
export function update(rolename: string, JSONBody: RoleResponse): Promise<RoleResponse> {
    return __request__('PUT', `/roles/${rolename}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve permissions for a single role
 */
export function read(rolename: string): Promise<RoleResponse> {
    return __request__('GET', `/roles/${rolename}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove the named role and dissociate any users from it
 */
export function remove(rolename: string): Promise<void> {
    return __request__('DELETE', `/roles/${rolename}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Retrieve the role's members
 */
export function getMembers(rolename: string): Promise<RoleMembershipResponse> {
    return __request__('GET', `/roles/${rolename}/members`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add a user to a role
 * @param JSONBody Placeholder because PUT requests should have a body. Set to '{}', the content will be ignored.
 */
export function addMember(rolename?: string, username?: string, JSONBody: string = '{}'): Promise<unknown> {
    return __request__('PUT', `/roles/${rolename}/members/${username}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Remove a user from a role
 */
export function removeMember(rolename?: string, username?: string): Promise<unknown> {
    return __request__('DELETE', `/roles/${rolename}/members/${username}`, null, {}, {
        'Accept': ['application/json']
    });
}
